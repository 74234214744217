import env from "@beam-australia/react-env";
import axios from "axios";
import { ServiceConfigFactory } from "./ServiceConfgFactory";

export class EnvironmentInfoService {
    public static frontEnd() {
        return "v" + env("VERSION");
    }

    public static async backEnd(): Promise<string> {
        try {
            const res = await axios.get<string>(
                EnvironmentInfoService.getBackendApiUrl() + "/EnvironmentInfo/Version",
                ServiceConfigFactory.createAxiosConfigWithoutAuth()
            );
            if (res.status === 200 && res.data !== null) {
                return "v" + res.data;
            }
        } catch {}
        return "unknown";
    }

    public static async getNotificationHubUrl(): Promise<string> {
        if (env("IS_K8S_ENV")) {
            return Promise.resolve(env("NOTIFICATION_HUB_ROOT_URL"));
        } else {
            const res = await axios.get<string>(
                this.getBackendApiUrl() + "/EnvironmentInfo/NotificationHubUrl",
                ServiceConfigFactory.createAxiosConfigWithoutAuth()
            );
            if (res.status < 300) {
                return Promise.resolve(res.data);
            }
            return Promise.reject("Could not load notificationHubUrl from backend");
        }
    }

    private static backendApiUrl: string | undefined = undefined;

    public static getBackendApiUrl(): string {
        if (this.backendApiUrl !== undefined) return this.backendApiUrl;

        // TODO: Always use NEXUS_API_URL when we switch to new infrastructure
        if (env("IS_K8S_ENV")) {
            this.backendApiUrl = env("NEXUS_API_URL");
        } else if (window.location.hostname.includes("green")) {
            this.backendApiUrl = env("NEXUS_API_URL_GREEN");
        } else if (window.location.hostname.includes("blue")) {
            this.backendApiUrl = env("NEXUS_API_URL_BLUE");
        } else {
            this.backendApiUrl = env("NEXUS_API_URL");
        }

        return this.backendApiUrl;
    }
}
