import { createReducer } from "@reduxjs/toolkit";
import * as actions from "../../PatientManagement/State/actions";

export type Workflow = "Signout" | "SessionControl";

export interface SessionReducerState {
    isEndSessionDialogVisible: boolean;
    isSignOutDialogVisible: boolean;
    workflow: Workflow | null;
}

const initialSessionReduerState: SessionReducerState = {
    isEndSessionDialogVisible: false,
    isSignOutDialogVisible: false,
    workflow: null,
};

const sessionReducer = createReducer<SessionReducerState>(initialSessionReduerState, builder => {
    builder.addCase(actions.setWorkflow, (state, action) => {
        state.workflow = action.payload;
    });
    builder.addCase(actions.resetWorkflow, state => {
        state.workflow = null;
    });
});

export default sessionReducer;
