import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { INotificationProps } from "@wsa/echo-components/dist/components/Notification";
import { RootState } from "../../../reduxStore";

export const notificationBarSelector = createSelector(
    (state: RootState) => state.notificationBar,
    notificationBar => notificationBar
);

const initialState: INotificationProps = {
    open: false,
    severity: "error",
    message: "",
    title: "",
};

const slice = createSlice({
    name: "notificationBar",
    initialState,
    reducers: {
        showNotificationBar: (state, action: PayloadAction<INotificationProps>) => {
            const { severity, message, title } = action.payload;
            return {
                ...state,
                severity,
                message,
                title,
                open: true,
            };
        },
        hideNotificationBar: state => {
            return {
                ...state,
                open: false,
            };
        },
    },
});

export const { showNotificationBar, hideNotificationBar } = slice.actions;
export default slice.reducer;
