import env from "@beam-australia/react-env";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { getTelemetry, trackTrace } from "./ApplicationInsights";

// Log notification.
const logNotification = (
    hubStyle: "ReactQuery" | "Legacy",
    boundedContextName: string,
    notificationName: string,
    details?: string
) => {
    details = details ? ` - ${details}` : "";

    logInfo(
        `Notification received: ${boundedContextName} - ${notificationName} (${hubStyle})${details}`
    );
};

// Logs with an arbitrary level.
const customTraceLog = (
    message: string,
    level: SeverityLevel = SeverityLevel.Information
): void => {
    const consoleLogSeverity = env("FE_CONSOLE_LOG_SEVERITY") ?? "Information";

    let castedSeverity = consoleLogSeverity as keyof typeof SeverityLevel;
    let enumCastedSeverity = SeverityLevel[castedSeverity];

    if (enumCastedSeverity <= level) {
        styleForLevel(message, level);
    }

    const aILogSeverity = env("FE_AI_LOG_SEVERITY") ?? "Warning";
    castedSeverity = aILogSeverity as keyof typeof SeverityLevel;
    enumCastedSeverity = SeverityLevel[castedSeverity];
    if (enumCastedSeverity <= level) {
        const telemetryClient = getTelemetry();
        trackTrace(telemetryClient, message, level);
    }
};

// Critical conditions.
const logCritical = (message: string): void => {
    customTraceLog(message, SeverityLevel.Critical);
};

// Runtime errors that do not require immediate action but should typically be logged and monitored.
const logError = (error: Error, prefixMessage: string = ""): void => {
    styleForLevel(`${prefixMessage}. Error: ${error.message}`, SeverityLevel.Error);
    customTraceLog(error.message, SeverityLevel.Error);
};

//  Exceptional occurrences that are not errors.
const logWarning = (message: string): void => {
    customTraceLog(message, SeverityLevel.Warning);
};

// Interesting events.
const logInfo = (message: string): void => {
    customTraceLog(message, SeverityLevel.Information);
};

//  Detailed debug information.
const logVerbose = (message: string): void => {
    customTraceLog(message, SeverityLevel.Verbose);
};

const styleForLevel = (message: string, level: SeverityLevel) => {
    switch (level) {
        case SeverityLevel.Verbose:
        case SeverityLevel.Information:
            console.log(message);
            break;
        case SeverityLevel.Warning:
            console.log("%c " + message, "color: #ff9900");
            break;
        case SeverityLevel.Error:
            console.log("%c " + message, "color:red");
            break;
        case SeverityLevel.Critical:
            console.log("%c " + message, "color: red; font-weight: bold");
            break;
        default:
            console.log(message);
    }
};

export { logCritical, logError, logInfo, logVerbose, logWarning, logNotification };
