export const organizationIdQueryParamKey = "auth0OrganizationId";
const localStorageOrganisationKey = organizationIdQueryParamKey;

export const getOrganizationId = () => {
    let organisationId = localStorage.getItem(localStorageOrganisationKey);

    if (!organisationId) {
        organisationId = getQueryParams()[organizationIdQueryParamKey] ?? null;
    }

    if (organisationId) {
        setOrganisationId(organisationId);
        console.log("OrganisationId: ", organisationId);
    }

    return organisationId;
};

const setOrganisationId = (organisationId: string) => {
    localStorage.setItem(localStorageOrganisationKey, organisationId);
};

const getQueryParams = () => {
    const params = new URLSearchParams(window.location.search);
    const queryParams: { [key: string]: string } = {};
    params.forEach((value, key) => {
        queryParams[key] = value;
    });
    return queryParams;
};
