import { createAction } from "@reduxjs/toolkit";

const actionTypePrefix = "simulationMode/";

export const openHACatalogOverlay = createAction(actionTypePrefix + "openHACatalogOverlay");
export const closeHACatalogOverlay = createAction(actionTypePrefix + "closeHACatalogOverlay");
export const setSimulationWorkflow = createAction(
    actionTypePrefix + "setSimulationWorkflow",
    (state: boolean) => ({ payload: state })
);
