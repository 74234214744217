import { createReducer } from "@reduxjs/toolkit";
import { EarSide } from "../../../Common/Types/EarSide";
import { SessionStorage } from "../../../Infrastructure/Services/SessionStorage";
import { UnitType, GraphViewType } from "../queries/useGetPerformanceGraphQuery";
import * as actions from "./actions";

interface PerformanceGraphState {
    graphView: GraphViewType;
    highlightedLegendItem: string | null;
    highlightedCurves: {
        [key in EarSide]: string[];
    };
    [GraphViewType.outputVsFrequency]: {
        unitType: UnitType;
    };
    [GraphViewType.insertionGainVsFrequency]: {
        unitType: UnitType;
    };
}

const initialState: PerformanceGraphState = {
    graphView: SessionStorage.getItem("graph_view", GraphViewType.insertionGainVsFrequency),
    highlightedLegendItem: null,
    highlightedCurves: {
        left: [],
        right: [],
    },
    [GraphViewType.outputVsFrequency]: {
        unitType: UnitType.dBHL,
    },
    [GraphViewType.insertionGainVsFrequency]: {
        unitType: UnitType.dB,
    },
};

const performanceGraphReducer = createReducer<PerformanceGraphState>(initialState, builder =>
    builder
        .addCase(actions.setGraphView, (state, { payload }) => {
            SessionStorage.setItem("graph_view", payload);
            state.graphView = payload;
        })
        .addCase(actions.setUnitTypeForGraphView, (state, { payload }) => {
            state[payload.graphView].unitType = payload.unitType;
        })
        .addCase(actions.highlightLegendItem, (state, action) => {
            state.highlightedLegendItem = action.payload;
        })
        .addCase(actions.highlightCurves, (state, action) => {
            state.highlightedCurves[action.payload.earSide] = action.payload.curves;
        })
);

export default performanceGraphReducer;
