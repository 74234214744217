import { createAction } from "@reduxjs/toolkit";
import { ReadoutError } from "./reducers";

const actionTypePrefix = "deviceAssignment/";
const programsActionTypePrefix = "programs/";

export const openConnectionOverlay = createAction(actionTypePrefix + "openConnectionOverlay");

export const closeConnectionOverlay = createAction(actionTypePrefix + "closeConnectionOverlay");

export const setAssignmentOngoing = createAction(
    actionTypePrefix + "setAssignmentOngoing",
    (animationState: boolean) => ({ payload: { animationState } })
);

export const setUpdatingActiveProgramToProgram = createAction(
    programsActionTypePrefix + "setUpdatingActiveProgramToProgram",
    (programId: string) => ({ payload: { programId } })
);

export const setReadoutError = createAction(actionTypePrefix + "setReadoutError",
    (readoutError: ReadoutError) => ({ payload: { readoutError } }));

export const closeReadoutError = createAction(actionTypePrefix + "closeReadoutError");
