import { useBaseQuery } from "../../../Infrastructure/queryClient/useQuery";
import { validateAndDecode } from "../../../Infrastructure/Services/ValidationUtil";
import * as io from "io-ts";

const FaultyHearingAidStateIO = io.type({
    id: io.string,
    retryable: io.boolean,
    reasonkey: io.string,
});

const FaultyHearingAidStateOrUndefinedIO = io.union([io.null, FaultyHearingAidStateIO]);
const SessionFaultsIO = io.type({
    leftHaFaultyState: FaultyHearingAidStateOrUndefinedIO,
    rightHaFaultyState: FaultyHearingAidStateOrUndefinedIO,
});

export type FaultyHearingAidState = io.TypeOf<typeof FaultyHearingAidStateIO>;

export type SessionFaults = io.TypeOf<typeof SessionFaultsIO>;

export const tempHearingSystemStateFaultyHearingAidStatusBaseUrl =
    "TemporaryHearingSystemState/FaultyHearingAidStatus";

const useGetHearingAidFaultyStateQuery = (patientId?: string) => {
    const { data } = useBaseQuery<SessionFaults>(
        {
            url: `${tempHearingSystemStateFaultyHearingAidStatusBaseUrl}/${patientId}`,
            boundedContext: "TemporaryHearingSystemState",
            notifyOnChangeOn: "HearingAidOperationStatusChanged",
            validate: dataToValidate => validateAndDecode(SessionFaultsIO, dataToValidate),
        },
        {
            enabled: !!patientId,
        }
    );
    return { ...data };
};

export { useGetHearingAidFaultyStateQuery };
