import * as t from "io-ts";
import { validateAndDecode } from "../../../Infrastructure/Services/ValidationUtil";
import { useBaseQuery } from "../../../Infrastructure/queryClient/useQuery";

const NexusVersionDataIO = t.type({
    buildVersion: t.string,
    releaseVersion: t.string,
});

type NexusVersionData = t.TypeOf<typeof NexusVersionDataIO>;

export const applicationConfigurationNexusVersionBaseUrl = "NexusVersion/Version";

const useGetBackendVersionQuery = () => {
    return useBaseQuery<NexusVersionData>({
        url: applicationConfigurationNexusVersionBaseUrl,
        boundedContext: "ApplicationConfiguration",
        validate: data => validateAndDecode(NexusVersionDataIO, data),
    });
};

export { useGetBackendVersionQuery, type NexusVersionData };
