import { createAction } from "@reduxjs/toolkit";
import { EarSide } from "../../../Common/Types/EarSide";
import { GraphViewType, UnitType } from "../queries";

const actionTypePrefix = "performanceGraph/";

export const setGraphView = createAction(
    actionTypePrefix + "setPerformanceGraphView",
    (graphView: GraphViewType) => ({ payload: graphView })
);

export const setUnitTypeForGraphView = createAction(
    actionTypePrefix + "setOutputVsFrequencyUnitType",
    (graphView: GraphViewType, unitType: UnitType) => ({ payload: { graphView, unitType } })
);

export const highlightLegendItem = createAction(
    actionTypePrefix + "highlightLegendItem",
    (legendItem: string | null) => ({ payload: legendItem })
);

export const highlightCurves = createAction(
    actionTypePrefix + "highlightCurves",
    (earSide: EarSide, curves: string[]) => ({ payload: { earSide, curves } })
);
