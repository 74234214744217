import { ApplicationInsights, SeverityLevel } from "@microsoft/applicationinsights-web";

export class Telemetry {
    private readonly applicationinsights: ApplicationInsights | null;

    constructor(applicationinsights: ApplicationInsights | null) {
        this.applicationinsights = applicationinsights;
    }

    public trackEvent(name: string, properties: { [name: string]: string } | null = null) {
        // .? syntax not supported by 'chai-friendly/no-unused-expressions'
        // eslint-disable-next-line
        this.applicationinsights?.trackEvent({ name }, properties ?? undefined);
    }

    public trackTrace(
        message: string,
        severityLevel: SeverityLevel,
        properties: { [name: string]: string } | null = null
    ) {
        // .? syntax not supported by 'chai-friendly/no-unused-expressions'
        // eslint-disable-next-line
        this.applicationinsights?.trackTrace({ severityLevel, message }, properties ?? undefined);
    }

    public trackException(exception: Error, properties: { [name: string]: string } | null = null) {
        // .? syntax not supported by 'chai-friendly/no-unused-expressions'
        // eslint-disable-next-line
        this.applicationinsights?.trackException(
            { exception, severityLevel: SeverityLevel.Error },
            properties ?? undefined
        );
    }

    public startTrackPage(name: string) {
        // .? syntax not supported by 'chai-friendly/no-unused-expressions'
        // eslint-disable-next-line
        this.applicationinsights?.startTrackPage(name);
    }

    public stopTrackPage(
        name: string,
        url: string,
        properties: { [name: string]: string } | null = null
    ) {
        // .? syntax not supported by 'chai-friendly/no-unused-expressions'
        // eslint-disable-next-line
        this.applicationinsights?.stopTrackPage(name, url, properties ?? undefined);
    }
}

export default Telemetry;
