import { createAction, createReducer } from "@reduxjs/toolkit";

const actionTypePrefix = "AboutBox/";

export const openAboutBox = createAction(actionTypePrefix + "open");

export const closeAboutBox = createAction(actionTypePrefix + "close");

interface AboutBoxState {
    isOpen: boolean;
}

const initialState: AboutBoxState = {
    isOpen: false,
};

export const aboutBoxReducer = createReducer<AboutBoxState>(initialState, builder =>
    builder
        .addCase(openAboutBox, state => ({
            ...state,
            isOpen: true,
        }))
        .addCase(closeAboutBox, state => ({
            ...state,
            isOpen: false,
        }))
);
