import { createReducer } from "@reduxjs/toolkit";
import * as actions from "./actions";
import { LocalStorage } from "../../../Infrastructure/Services/LocalStorage";

interface HACatalogState {
    showHACatalogOverlay: boolean;
    isSimulationWorkflow: boolean;
}

const initialState: HACatalogState = {
    showHACatalogOverlay: false,
    isSimulationWorkflow: LocalStorage.getItem("simulation_workflow", false),
};

const simulationModeReducer = createReducer<HACatalogState>(initialState, builder =>
    builder
        .addCase(actions.openHACatalogOverlay, state => ({
            ...state,
            showHACatalogOverlay: true,
        }))
        .addCase(actions.closeHACatalogOverlay, state => ({
            ...state,
            showHACatalogOverlay: false,
        }))
        .addCase(actions.setSimulationWorkflow, (state, { payload }) => {
            LocalStorage.setItem("simulation_workflow", payload);
            state.isSimulationWorkflow = payload;
        })
);

export default simulationModeReducer;
