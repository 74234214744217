export const KEY_PREFIX = "fitxp_";
export type LocalStorageKey = "simulation_workflow";

export class LocalStorage {
    static getItem<T>(key: LocalStorageKey, initialValue: T): T {
        const item = window?.localStorage.getItem(`${KEY_PREFIX}${key}`);

        return item ? JSON.parse(item) : initialValue;
    }

    static setItem<T>(key: LocalStorageKey, value: T) {
        if (typeof window !== "undefined") {
            window.localStorage.setItem(`${KEY_PREFIX}${key}`, JSON.stringify(value));
        }
    }

    static removeItem(key: LocalStorageKey) {
        if (typeof window !== "undefined") {
            window.localStorage.removeItem(`${KEY_PREFIX}${key}`);
        }
    }
}
