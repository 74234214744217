import { AxiosResponse, InternalAxiosRequestConfig, Method } from "axios";
import { analyticsService } from "./Infrastructure/Services/AnalyticsService";
import { getAppInsights } from "./Infrastructure/Services/ApplicationInsights";
import { logInfo, logWarning } from "./Infrastructure/Services/LoggingService";

let nextRequestId = 1;
const sessionBegin = "session.begin";

function getDataForPutPostRequest(method?: Method | string, data?: any): string {
    if (method?.toLowerCase() === "put" || method?.toLowerCase() === "post") {
        return "data: " + dataToString(data);
    }
    return "";
}

function dataToString(input: any): string {
    if (!input) return "";

    let data = JSON.stringify(input);
    data = limitStringLength(data, 400);
    return data;
}

function logResponse(response: AxiosResponse) {
    const customConfig: CustomAxiosRequestConfiguration = response.config;
    const requestId = customConfig["requestId"];
    const duration = customConfig["date"] ? Date.now() - customConfig["date"] : 0;
    const status = `status: ${response.status} ${response.statusText}`;
    let data = `data: ${dataToString(response.data)}`;
    data = limitStringLength(data, 300);

    logInfo(`HTTP response id: ${requestId}, ${status}, duration: ${duration}ms, ${data}`);
}

export interface CustomAxiosRequestConfiguration<D = any> extends InternalAxiosRequestConfig<D> {
    requestId?: number;
    date?: number;
    errorContext?: Error;
}

export const onFulfilledRequest = (
    config: CustomAxiosRequestConfiguration
): CustomAxiosRequestConfiguration => {
    const { method, url, data } = config;
    const requestId = nextRequestId;
    nextRequestId++;
    logInfo(
        `HTTP request id: ${requestId} ${method} ${url} ${getDataForPutPostRequest(method, data)}`
    );

    config.requestId = requestId;
    config.date = Date.now();
    config.errorContext = new Error("Original stacktrace:");

    return config;
};

export const onRejectedRequest = function (error: any): any {
    if (error?.response?.status !== 404)
        logWarning(`HTTP request error: ${error.message}, status: ${error?.response?.status}`);
    if (error?.response?.status >= 500) {
        analyticsService.trackAnalyticsException(
            error,
            // eslint-disable-next-line
            { request_url: error.config.url },
            sessionBegin
        );
    }
    return Promise.reject(error);
};

export const onFulfilledResponse = function (response: AxiosResponse): AxiosResponse {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    logResponse(response);
    return response;
};

export type ErrorResponseEventListener = (error: any) => void;
const onErrorResponseEventListeners: ErrorResponseEventListener[] = [];

export const addErrorResponseEventListener = (eventListener: ErrorResponseEventListener) => {
    onErrorResponseEventListeners.push(eventListener);
};

export const removeErrorResponseEventListener = (eventListener: ErrorResponseEventListener) => {
    const index = onErrorResponseEventListeners.indexOf(eventListener);
    if (index !== -1) {
        onErrorResponseEventListeners.splice(index, 1);
    }
};

export const onRejectedResponse = function (error: any): any {
    const originalStackTrace = error.config?.errorContext?.stack;
    if (originalStackTrace) {
        error.stack = originalStackTrace;
    }

    const requestId = error.config ? error.config["requestId"] : "NA";

    //404 is an expected return in some workflows:
    if (error.response?.status === 404) {
        logInfo(
            `HTTP response ${requestId} error: ${error.message}, status: ${error.response?.status}`
        );
    } else {
        logWarning(
            `HTTP response ${requestId} error: ${error.message}, status: ${
                error.response?.status
            }, operation_Id: ${getAppInsights()?.context?.telemetryTrace.traceID}`
        );
        if (error.response) {
            logResponse(error.response);
            logInfo(`Connectivity Issue in ${error.response.config.url} : ${error.message}`);
        } else {
            logInfo(`Connectivity Issue: ${error.message} ${error.stack}`);
        }
    }

    if (error?.response?.status >= 500) {
        analyticsService.trackAnalyticsException(
            error,
            // eslint-disable-next-line
            { response_url: error.config.url },
            sessionBegin
        );
    }

    onErrorResponseEventListeners.forEach(eventListener => {
        try {
            eventListener(error);
        } catch (ex) {
            analyticsService.trackAnalyticsException(
                ex as Error,
                { message: "ErrorResponseEventListener threw an exception" },
                sessionBegin
            );
        }
    });

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    return Promise.reject(error);
};

function limitStringLength(data: string, maxlength: number): string {
    return data.length > maxlength
        ? `${data.substring(0, maxlength - 50)} ... ${data.substring(data.length - 50)}`
        : data;
}
