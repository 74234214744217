export type SessionStorageKey = "graph_view";
export const KEY_PREFIX = "fitxp_";

export class SessionStorage {
    static getItem<T>(key: SessionStorageKey, initialValue: T): T {
        const item = window?.sessionStorage.getItem(`${KEY_PREFIX}${key}`);

        return item ? JSON.parse(item) : initialValue;
    }

    static setItem<T>(key: SessionStorageKey, value: T) {
        if (typeof window !== "undefined") {
            window.sessionStorage.setItem(`${KEY_PREFIX}${key}`, JSON.stringify(value));
        }
    }
}
