import { v4 as uuidv4 } from "uuid";
import isNil from "lodash/isNil";

/**
 * The EventContext class maintains a tree of events related to each other.
 * When an ancestor event is popped off the tree all the descendant events are popped as well.
 * Setting an event using the update function will effectively pop that event and push the new event on the tree stack.
 * Events come in two flavours: thin and fat. Thin events are refered by a guid, while fat events have their
 * data embedded in the context.
 */
class EventContext {
    /**
     * A dictionary from event name to
     * - guid (if thin event)
     * - embedded data (if fat event)
     * This dictionary constitutes the tree that will have its node popped on update.
     */
    private readonly context: { [name: string]: string | any } = {};
    /**
     * A dictionary from event name to parent event name.
     * This dictionary maintains the shape of the tree.
     */
    private readonly parents: { [name: string]: string | undefined } = {};
    /**
     * This is a list of event names of events to be treated as fat.
     */
    private readonly embedded: string[];
    /**
     * @param context - a dictionary of contexts containing as values 'feature' (if fat) or to a guid (if thin)
     * @param parents - a dictionary of parents containing as values names of eventName parents
     * @param embedded - a list of event names to be treated as fat. If not given the embedded list of fat events will instead be read from file "context/embedded.json".
     */
    constructor(context = {}, parents = {}, embedded: string[] = []) {
        this.context = context;
        this.parents = parents;
        this.embedded = embedded;
    }
    /**
     * The clear method will pop the event given by node off the event tree given by the 'context' member
     * as well as all of its descendants.
     * @param node - the name of the event to pop/clear
     */
    private clear(node: string | undefined): void {
        for (let stack = []; node; node = stack.pop()) {
            delete this.context[node];
            for (const child in this.parents) if (this.parents[child] === node) stack.push(child);
        }
    }
    /**
     * Replaces the context given by the event tree.
     * Will clear the tree from the node given by the 'name' param.
     * Maps the event given by 'name' to either the data given by 'feature' (if fat) or to a guid (if thin).
     * @param name - the name of the event to add to the event tree
     * @param feature - the data to set for the event in case of a fat event
     * @param parent - the name of the parent event of the event given by the 'name' param
     */
    replace(name: string, feature: any, parent: string | undefined): any {
        this.clear(name);
        this.context[name] = this.embedded.includes(name) ? feature : uuidv4();
        this.parents[name] = parent;
        return this.context;
    }
    /**
     * Gets the context for given eventName. Retrieves subtree of stored context table containing path leading from
     * the root to the node denoted as eventName. For a given structure of context/parents like below:
     - session.begin 
        - selection.change
            - connection.change
                - programA
                    - feature1
                    - feature2
                - programB
                    - feature3
                    - feature4
     and eventName==feature1 resulting subtree would be:
     - session.begin 
        - selection.change
            - connection.change
                - programA
                    - feature1
     * @param eventName - the name of the event
     * @returns 
     */
    getContextForParticularEvent(eventName: string): any {
        let currentEvent: string | undefined = eventName;
        const trimmedContext: { [name: string]: string | any } = {};
        while (!isNil(currentEvent)) {
            trimmedContext[currentEvent] = this.context[currentEvent];
            currentEvent = this.parents[currentEvent];
        }
        return trimmedContext;
    }
    /**
     * Gets parent for given eventName.
     * @param eventName - the name of the event
     * @returns
     */
    getParent(eventName: string): string | undefined {
        return this.parents[eventName];
    }
}
export let currentEventContext: EventContext | undefined = undefined;
export const eventContext = () => {
    if (!currentEventContext)
        throw new Error(
            `${EventContext.name} is NOT yet initialized. It must be initialized first with ${initializeEventContext.name}.`
        );
    return currentEventContext;
};
function setEventContext(newEventContext: any) {
    currentEventContext = new EventContext(
        newEventContext.context,
        newEventContext.parents,
        newEventContext.embedded
    );
}
function initializeEventContext(embeddedData: string[]) {
    if (currentEventContext)
        throw new Error(`${EventContext.name} reinitialized. It must be initialized only ONCE.`);
    currentEventContext = new EventContext({}, {}, embeddedData);
}
export { setEventContext, initializeEventContext };
export default EventContext;
