import axios from "axios";
import { ServiceConfigFactory } from "../Services/ServiceConfgFactory";
import { EnvironmentInfoService } from "../Services/EnvironmentInfoService";

class HttpError extends Error {
    statusCode: number | undefined;
    constructor(message: string, statusCode: number | undefined) {
        super(message);
        this.statusCode = statusCode;
    }
}

const query = async <TData>(url: string, accessTokenGenerator: () => Promise<string>) => {
    return axios
        .get<TData>(
            `${EnvironmentInfoService.getBackendApiUrl()}/${url}`,
            await ServiceConfigFactory.createAxiosConfigWithAuth(accessTokenGenerator)
        )
        .then(result => result.data)
        .catch(error => {
            if (axios.isAxiosError(error)) {
                throw new HttpError(`${error.name}: ${error.message}`, error.response?.status);
            }
            throw error;
        });
};

export { query, type HttpError };
