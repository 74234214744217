import { createAction } from "@reduxjs/toolkit";
import { Workflow } from "./reducers";

const actionTypePrefix = "patientManagement/";

export const setWorkflow = createAction(
    actionTypePrefix + "setWorkflow",
    (workflow: Workflow) => ({ payload: workflow })
);

export const resetWorkflow = createAction(
    actionTypePrefix + "resetWorkflow"
);