import { createReducer } from "@reduxjs/toolkit";
import * as actions from "./actions";

export type ReadoutError = "None" | "BinauralError" | "MonauralError" | "HAUnreadable";

export interface DeviceAssignmentState {
    showConnectionOverlay: boolean;
    assignmentOngoing: boolean;
    readoutError: ReadoutError;
}

export type DetectionState = "NOT_DETECTING" | "DETECTING" | "DETECTION_DONE";
export type ValidationState = "SUPPORTED" | "UNSUPPORTED" | "VALID" | "INVALID_WITH_REASON";

const initialState: DeviceAssignmentState = {
    showConnectionOverlay: false,
    assignmentOngoing: false,
    readoutError: "None",
};

export const widexDeviceAssignmentReducer = createReducer<DeviceAssignmentState>(
    initialState,
    builder =>
        builder
            .addCase(actions.openConnectionOverlay, state => ({
                ...state,
                showConnectionOverlay: true,
            }))
            .addCase(actions.closeConnectionOverlay, state => ({
                ...state,
                showConnectionOverlay: false,
            }))
            .addCase(actions.setAssignmentOngoing, (state, action) => ({
                ...state,
                assignmentOngoing: action.payload.animationState,
            }))
            .addCase(actions.setReadoutError, (state, action) => ({
                ...state,
                readoutError: action.payload.readoutError,
            }))
            .addCase(actions.closeReadoutError, state => ({
                ...state,
                readoutError: "None",
            }))
);

interface ProgramsReducerState {
    updatingActiveProgramToProgram: string | null;
}

const programsReducerInitialState: ProgramsReducerState = {
    updatingActiveProgramToProgram: null,
};

export const programsReducer = createReducer<ProgramsReducerState>(
    programsReducerInitialState,
    builder =>
        builder.addCase(actions.setUpdatingActiveProgramToProgram, (state, action) => {
            state.updatingActiveProgramToProgram = action.payload.programId;
        })
);
