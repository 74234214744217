import { useCallback } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { analyticsService } from "../Infrastructure/Services/AnalyticsService";

export const AppInsightsTrackingBoundary = ({ children }: { children: React.ReactNode }) => {
    const logErrorCallback = useCallback((error: Error) => {
        analyticsService.trackAnalyticsException(error, {}, "session.begin");
    }, []);
    return (
        <ErrorBoundary
            fallback={<div>Something went wrong. Try to refresh the page.</div>}
            onError={logErrorCallback}>
            {children}
        </ErrorBoundary>
    );
};
